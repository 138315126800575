import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Skeleton } from 'antd'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import { any, equals, map } from 'ramda'
import React, { FC } from 'react'
import styled from 'styled-components'
import { StringParam } from 'use-query-params'

import bannerImage from '../../assets/static/banner/banner3.jpg'
import PageBanner from '../../components/Banner/PageBanner'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import { LatestCase, PatientCase } from '../../components/Cards'
import { ClinicFilter, RadioFilter } from '../../components/Filter'
import { GridItem, GridLayout } from '../../components/Grid'
import { ThemeProvider } from '../../components/Layout'
import LoadMore from '../../components/LoadMore'
import PageContent from '../../components/PageContent'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { CaseCategory, CaseListDocs, CaseListQuery, CaseListQueryVariables, Location } from '../../types/types'
import useFormQuery from '../../utils/hooks/useFormQuery'

const caseCategoryFilter = ['ALL', ...Object.values(CaseCategory)]

function isCaseCategory(category: CaseCategory | string): category is CaseCategory {
  return any(equals(category), Object.values(CaseCategory))
}

function isCaseLocation(location: Location | string): location is Location {
  return any(equals(location), Object.values(Location))
}

const pageLimit = 1000

export const caseListQuery = gql`
  query CaseList($query: CasesQuery, $page: Int, $limit: Int, $sort: String) {
    cases(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        category
        location
        content
        created
        alias
        publishedDate
        name
        doctor
        title
        thumbnailImage
      }
      total
      page
    }
  }
`

const StyledPageContent = styled(PageContent)`
  margin-bottom: 84px;
`

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const BreadcrumbBarWrapper = styled.div`
  margin: ${space.l}px 0;
`
const LatestCardWrapper = styled.div`
  margin-bottom: ${space.xl}px;
`

interface CaseGalleryProps {
  source: CaseListDocs[]
  total: number
  loading: boolean
}

const CaseGallery = (props: CaseGalleryProps) => {
  const { source, total, loading } = props
  const caseList = source
  const GeneralList = caseList.slice(1)

  if (loading) {
    return (
      <>
        <Skeleton paragraph={{ rows: 5 }} active />
      </>
    )
  }

  if (caseList.length === 0) {
    return <h3>這個分類尚無資料</h3>
  }

  return (
    <>
      <LatestCardWrapper>
        <LatestCase
          caseId={caseList[0].id}
          category={caseList[0].category}
          imageUrl={caseList[0].thumbnailImage}
          publishedDate={moment(caseList[0].publishedDate).format('YYYY.MM.DD')}
          title={caseList[0].title}
          location={caseList[0].location}
        />
      </LatestCardWrapper>
      <LoadMore
        templateColumns={['100%', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
        rowGap={[`${space.s}px`, `${space.m}px`, `${space.m}px`]}
        columnGap={[`${space.s}px`, `${space.l}px`, `${space.m}px`]}
        defaultItems={6}
        perClick={6}
        amount={total}
        itemList={GeneralList}
      >
        {items => (
          <PatientCase
            key={items.id}
            caseId={items.id}
            category={items.category}
            imageUrl={items.thumbnailImage}
            doctor={items.doctor}
            publishedDate={moment(items.publishedDate).format('YYYY.MM.DD')}
            title={items.title}
            alias={items.alias}
            location={items.location}
            sidespace={space.m}
          />
        )}
      </LoadMore>
    </>
  )
}

const formInput = {
  category: StringParam,
  location: StringParam
}

type Props = RouteComponentProps

const CaseList: FC<Props> = () => {
  const { formatMessage } = useIntl()

  const { formQuery, handleFormChange } = useFormQuery(formInput)

  const categoryFilter = formQuery?.category ?? 'ALL'
  const locationFilter = formQuery?.location ?? 'ALL'

  const { data, loading } = useQuery<CaseListQuery, CaseListQueryVariables>(caseListQuery, {
    variables: {
      query: {
        category: isCaseCategory(categoryFilter) ? [categoryFilter] : undefined,
        location: isCaseLocation(locationFilter) ? [locationFilter] : undefined
      },
      limit: pageLimit
    }
  })
  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.case' }),
      path: '/case'
    }
  ]

  const handleSelect = value => {
    if (value === 'ALL' || Object.values(CaseCategory).includes(value)) {
      handleFormChange({ category: value })
    }
  }

  const handleLocation = value => {
    if (value === 'ALL' || Object.values(Location).includes(value)) { 
      handleFormChange({ location: value })
    }
  }

  const source = data?.cases?.docs ?? []
  const total = data?.cases?.total ?? 0

  return (
    <>
      <PageBanner imageUrl={bannerImage} title={formatMessage({ id: 'pageTitle.case' })} subtitle='Case' />
      <StyledPageContent>
        <ThemeProvider>
          <GridLayout templateColumns={['100%', 'repeat(12, 1fr)']}>
            <GridItem column={['auto', '3 / span 8']}>
              <BreadcrumbBarWrapper>
                <BreadcrumbBar routes={routes} />
              </BreadcrumbBarWrapper>
              <StyledContentTitle>{formatMessage({ id: 'pageTitle.case' })}</StyledContentTitle>
              <ClinicFilter onSelect={handleLocation} value={locationFilter} />
              <RadioFilter
                value={categoryFilter}
                onSelect={handleSelect}
                options={map(filterType => ({ label: formatMessage({ id: `case.${filterType}` }), value: filterType }), caseCategoryFilter)}
              />
              <CaseGallery source={source} total={total} loading={loading} />
            </GridItem>
          </GridLayout>
        </ThemeProvider>
      </StyledPageContent>
    </>
  )
}

export default CaseList
