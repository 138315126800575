import React from 'react'
import styled, { css } from 'styled-components'

import StarImg from '../../assets/images/star.svg'
import { PrimaryDark } from '../../constants/color'
import { space } from '../../constants/length'
import { H2, H3 } from '../../constants/newText'
import { textSize } from '../../constants/text'
import { viewport } from '../../constants/viewport'

const StarImgStyled = css`
  width: 20px;
  height: 20px;
  margin: ${space.s}px;
  background-image: url(${StarImg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
`
interface H2TitleProps {
  children: string
}

const H2Title = styled.h2<H2TitleProps>`
  position: relative;
  padding-bottom: ${space.s}px;

  font-size: ${textSize.h2Web}px;
  font-weight: 500;
  color: ${PrimaryDark};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 50%;

    margin: 0 auto;
    border-bottom: 1px solid ${PrimaryDark};
  }

  @media (max-width: ${viewport.tablet - 1}px) {
    font-size: ${textSize.h2Mobile}px;
  }
`

interface WrapperProps {
  marginBottom?: string
}

const TitleWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '60px')};
`

const Title = styled.h2`
  ${H2}
`

const SpecialSubTitle = styled.h3`
  ${H3}
  display: flex;
  align-items: center;

  &:after,
  :before {
    ${StarImgStyled}
    content: '';
    display: block;
  }
`

type TitleProps = WrapperProps & {
  title: string
  subtitle: string
  color?: string
}

const H2TitleWithSubTitle = (props: TitleProps) => {
  const { title, subtitle, marginBottom, color } = props

  return (
    <TitleWrapper marginBottom={marginBottom}>
      <Title color={color}>{title}</Title>
      <SpecialSubTitle color={color}>{subtitle}</SpecialSubTitle>
    </TitleWrapper>
  )
}

export { H2Title, H2TitleWithSubTitle }
