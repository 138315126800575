import { message } from 'antd'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import CardBackground from '../../assets/images/card_backGround.jpg'
import clinicInfoList from '../../components/Clinic/Info'
import { GridLayout } from '../../components/Grid'
import { H2TitleWithSubTitle } from '../../components/Titles/H2Title'
import { space } from '../../constants/length'
import { LightGrey, Primary, White } from '../../constants/newColor'
import { Caption, H3 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
`

interface CardWrapperProps {
  color: string
  index?: number
  hassite: boolean
}
const CardWrapper = styled.div<CardWrapperProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid ${Primary};
  background-color: ${White};

  border-radius: 5px;
  padding: ${space.m}px ${space.xs}px;
  position: relative;

  &::after {
    content: '';
    width: 31px;
    height: 31px;
    position: absolute;
    border-radius: 0 0 ${space.xs}px 0;
    bottom: 0;
    right: 0;
    background-image: url(${CardBackground});
    background-color: ${props => (props.hassite ? props.color : LightGrey)};
    background-blend-mode: lighten;
    background-size: 31px 31px;
  }

  @media (min-width: ${viewport.tablet}px) {
    max-width: 464px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    max-width: 322px;
  }
`

interface TagProps {
  color: string
}
const Tag = styled.span<TagProps>`
  ${Caption}
  display: flex;
  margin-right: 8px;
  color: ${props => (props.color ? props.color : Primary)};
  ::before {
    content: '#';
    display: block;
  }
  &:last-child {
    margin-right: 0;
  }
`

const LogoImg = styled.img`
  margin: 0 auto;
  @media (min-width: ${viewport.tablet}px) {
    width: 120px;
    height: 120px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    width: 90px;
    height: 90px;
  }
`

interface NameProps {
  ishover: boolean
  color: string
}

const Name = styled.h2<NameProps>`
  @media (min-width: ${viewport.tablet}px) {
    ${H3}
    z-index: 3;
    color: ${props => (props.ishover ? props.color : Primary)};
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none
  }
`

interface ClinicProps {
  index: number
  logoImageUrl: string
  name: string
  color: string
  tags: string[]
  hassite: boolean
  onClick?: () => void
}
const Clinic = (props: ClinicProps) => {
  const { name, color, tags, hassite, index, logoImageUrl, onClick } = props
  const [hovering, setHovering] = useState(false)

  return (
    <CardWrapper
      color={color || '#ad5b9c'}
      onMouseEnter={() => hassite && setHovering(true)}
      onMouseLeave={() => hassite && setHovering(false)}
      index={index}
      hassite={hassite}
      onClick={onClick}
    >
      <GridLayout templateColumns={['100%']} rowGap={['8px']}>
        <TagWrapper>
          {tags.map((tag, index) => (
            <Tag key={index} color={color}>
              {tag}
            </Tag>
          ))}
        </TagWrapper>
        <LogoImg src={logoImageUrl} alt={`舒服美特約教育中心-${name}`} />
        <Name color={color} ishover={hovering}>
          {name}
        </Name>
      </GridLayout>
    </CardWrapper>
  )
}

export const homePageMaxWidth = 1137

const Wrapper = styled.section`
  max-width: ${homePageMaxWidth}px;
  margin: 0 auto;
  padding: 0 ${space.m}px;
  width: 100%;
`

const ClinicSection = () => {
  const { formatMessage } = useIntl()

  const [viewport, setViewport] = useState('mobile')

  const handleRWD = () => {
    // iphone 14 pro max
    if (window.innerWidth > 430) setViewport('desktop')
    else setViewport('mobile')
  }

  useEffect(() => {
    window.addEventListener('resize', handleRWD)
    handleRWD()

    return () => {
      window.removeEventListener('resize', handleRWD)
    }
  }, [])

  const clinicList = clinicInfoList
    .flat()
    .map(clinic => clinic.clinics)
    .flat()

  const ShowPopup = () => {
    message.info('Oops!頁面還在準備中')
  }

  return (
    <Wrapper>
      {viewport !== 'mobile' && <H2TitleWithSubTitle title={formatMessage({ id: 'title.clinic' })} subtitle={formatMessage({ id: 'subtitle.clinic' })} />}
      <GridLayout
        templateColumns={['repeat(2, 1fr)', 'repeat(5, 1fr)']}
        rowGap={[`${space.m}px`]}
        columnGap={[`${space.m}px`]}
      >
        {clinicList.map((clinic, index) =>
          clinic.route ? (
            <Link to={clinic.route}>
              <Clinic
                key={clinic.name}
                name={clinic.name}
                color={clinic.primaryColor}
                tags={clinic.tags}
                hassite={clinic.hassite}
                index={index}
                logoImageUrl={clinic.logoImageUrl}
              />
            </Link>
          ) : (
            <Clinic
              key={clinic.name}
              name={clinic.name}
              color={clinic.primaryColor}
              tags={clinic.tags}
              hassite={clinic.hassite}
              index={index}
              logoImageUrl={clinic.logoImageUrl}
              onClick={ShowPopup}
            />
          )
        )}
      </GridLayout>
    </Wrapper>
  )
}

export default ClinicSection
